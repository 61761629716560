<!--
 * @Descripttion: 整理逻辑,添加备注!!!
 * @version: 
 * @Author: Duanshuai
 * @Date: 2021-06-23 10:06:28
 * @LastEditors: ------
 * @LastEditTime: 2021-07-22 10:48:40
-->
// 客户管理详情页

<template>
  <div class="big_box">
    <TitleNav class=" ">
      <template #one>
        <span @click="go('home')" class="cursor">
          首页 >
        </span>
      </template>
      <template #two>
        <span @click="go('customerList')" class="cursor">
          护理员管理 >
        </span>
      </template>
      <template #three>
        <span @click="go" class="cursor"> {{ info.name }}详情页 </span>
      </template>
      <template v-slot:slotCon>
        <div class="personal_detail">
          <div class="personal_detail_info base_666_color">
            <div class="pay_top">
              <img src="../image/info.png" alt="" />
              <span class="base_333_color ">本人信息</span>
            </div>
            <div class="base_info ">
              <span>用户姓名：{{ info.name }}</span>
              <span>用户手机号：{{ info.mobile }}</span>
            </div>
            <div class="base_info ">
              <span>用户身份证：{{ info.idCard }}</span>
              <span>所在城市：{{ info.mycity }}</span>
            </div>
            <div class="base_info ">
              <span>居住地址：{{ info.street }}</span>
              <!-- <span></span> -->
            </div>
          </div>
          <div class="personal_detail_family  base_666_color">
            <div class="pay_top family_top">
              <img src="../image/family.png" alt="" />
              <span class="base_333_color ">家庭成员</span>
            </div>
            <div
              class="family_name"
              v-if="info.consumerFamilyMemberList[0].city"
            >
              <div class="family_box cursor">
                <div class="name_tip base_white_color">
                  {{
                    info.consumerFamilyMemberList[0].kinship == 10
                      ? "父亲"
                      : info.consumerFamilyMemberList[0].kinship == 11
                      ? "母亲"
                      : info.consumerFamilyMemberList[0].kinship == 12
                      ? "爱人"
                      : info.consumerFamilyMemberList[0].kinship == 19
                      ? "兄妹"
                      : info.consumerFamilyMemberList[0].kinship == 14
                      ? "儿子"
                      : info.consumerFamilyMemberList[0].kinship == 15
                      ? "女儿"
                      : info.consumerFamilyMemberList[0].kinship == 18
                      ? "朋友"
                      : ""
                  }}
                </div>
                {{ info.consumerFamilyMemberList[0].name }}
                <span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; > </span>
              </div>
            </div>
            <div class="family_name" v-else>
              <div class="family_box">
                <div class="name_tip base_white_color">#家属标签#</div>
                家属信息暂未填写
                <span> &nbsp;&nbsp;&nbsp;&nbsp; > </span>
              </div>
            </div>
          </div>
        </div>
      </template>
    </TitleNav>
    <ebutton class="right_btn ">
      <template #one>
        <button @click="goContract" class="btn1 cursor base_white_color">
          创建服务合同
        </button>
      </template>
      <template #two>
        <button @click="createUser" class="btn2 cursor base_white_color">
          创建客户信息
        </button>
      </template>
      <!-- <template v-slot:three> 这是插入到three插槽的内容 </template> -->
    </ebutton>
  </div>
</template>

<script>
import TitleNav from "../components/TitleNav"; // 上部导航 插槽
import ebutton from "../components/RightList"; // 右侧导航 插槽
import { getItem } from "../utils/storage";

export default {
  data() {
    return {
      info: getItem("consumer") || {
        city: "",
        consumerFamilyMemberList: [{}],
        county: "",
        detailAddress: "",
        idCard: "",
        mobile: "",
        name: "",
        province: "",
        street: "",
        status: "10",
      },
    };
  },
  components: { TitleNav, ebutton },
  created() {},
  methods: {
    goContract() {
      this.$router.push("/serveManage");
    },
    createUser() {
      this.$router.push("/infoOne");
    },
    go(key) {
      switch (key) {
        case "home":
          this.$router.push("/home");
          break;
        case "customerList":
          this.$router.push("/customerList");
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style scoped>
.big_box {
  position: relative;
}

.right_btn {
  padding: 18px 0;
  font-size: 20px;
  /* float: right; */
  height: 100%;
  position: absolute;
  right: 0;
  top: 0px;
  z-index: 1;
}
.right_btn button {
  width: 172px;
  height: 64px;
  border-radius: 10px;
  margin-top: 32px;
  box-shadow: 0px 0px 16px 0px rgba(205, 210, 243, 0.4);
}
.btn1 {
  background: linear-gradient(90deg, #aee49e 0%, #82d3ea 100%);
}
.btn2 {
  background: linear-gradient(90deg, #fdb179 0%, #ff9158 100%);
}

/* ------------------------------------------------------------------------------------------ */

.pay_top {
  padding-bottom: 34px;
  border-bottom: 1px solid #e2e2e2;
}
.pay_top img {
  width: 24px;
  height: 24px;
  margin-right: 18px;
  vertical-align: sub;
}
.pay_top span {
  font-size: 22px;
}
.family_top {
  margin-top: 40px;
}
.base_info {
  font-size: 20px;
  display: flex;
  height: 50px;
  line-height: 50px;
  margin: 16px 0 16px 40px;
}
.base_info span:nth-child(1) {
  flex: 2;
}
.base_info span:nth-child(2) {
  flex: 4;
}
.family_name {
  display: flex;
  margin-top: 34px;
  margin-bottom: 20px;
  margin-left: 30px;
}
.family_box {
  width: 324px;
  height: 137px;
  background: #ffffff;
  box-shadow: 0px 0px 18px 0px rgba(205, 210, 243, 0.185);
  border-radius: 8px;
  margin-left: 20px;
  overflow: hidden;
  font-size: 20px;
  text-align: center;
}
.name_tip {
  font-size: 16px;
  width: 127px;
  height: 35px;
  background: #d8d8d8 linear-gradient(90deg, #fdb179 0%, #ff9158 100%);
  border-radius: 18px;
  margin-top: 18px;
  text-align: center;
  line-height: 35px;
  /* padding: 0 16px; */
  margin-left: -12px;
}
</style>
